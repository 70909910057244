import React from 'react';

class TheFormComponent extends React.Component {
    state = {
        FIRSTNAME: "",
        EMAIL: "",
        PHONE_NUMBER: "",
        PROJECT_INFO: "",
        BUDGET: "",
        OPT_IN: false,
        isSubmitting: false,
        submitSuccess: false,
        submitFail: false,

      }
    
      handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const checked = target.checked;
        if(name === "OPT_IN"){
            this.setState({
                [name]: checked,
            });    
        }
        else {
            this.setState({
              [name]: value,
            });
        }
        
      }
    
      handleSubmit = event => {
        event.preventDefault();
        this.submitData({
          'FIRSTNAME': `${this.state.FIRSTNAME}`, 'EMAIL': `${this.state.EMAIL}`, 'PHONE_NUMBER' : `${this.state.PHONE_NUMBER}`, 'PROJECT_INFO': `${this.state.PROJECT_INFO}`, 'BUDGET': `${this.state.BUDGET}`, 'OPT_IN' : `${this.state.OPT_IN}`
        })
        //.then(res => navigate("/thank-you/"))
        .catch(error => alert(error))
      }
    
      submitData = async data => {
        const url = '/api/sendinblue';
    
        try {
          this.setState({
            isSubmitting: true,
          });
          await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify(data),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            }
          });
          this.setState({
            submitSuccess: true,
          });
          this.clearStateData();

        } catch (e) {
          this.setState({
            isSubmitting: false,
          });
          this.setState({
            submitFail: true,
          });
          console.log('Error ocurred when trying to submit data\n', e);
        }
      }
    
      clearStateData = () => {
        this.setState({
          FIRSTNAME: "",
          EMAIL: "",
          PHONE_NUMBER: "",
          PROJECT_INFO: "",
          BUDGET: "",
          OPT_IN: false,
          isSubmitting: false,
        });
      }

    render() {
        return (
        <form id="sib-form" className="cform" name="contact-form"
        onSubmit={this.handleSubmit}>
            <input type="hidden" name="bot-field" />
            
            <div className="cform__label floating-label">
                <input
                className="cform__input"
                type="text"
                placeholder="Name"
                name="NAME"
                id="NAME"
                autoComplete="off"
                value={this.state.EMAIL} onChange={this.handleInputChange}
                />
            </div>
            
            <div className="cform__label floating-label">
                <input
                className="cform__input"
                type="text"
                placeholder="Email"
                name="EMAIL"
                id="EMAIL"
                autoComplete="off"
                value={this.state.EMAIL} onChange={this.handleInputChange}
                />
            </div>
            <button id="ud-formsend" className="cform__btn" type="submit" name="submit">
              Send
            </button>
        </form>
    )
}
}

export default () => {
return (
    <TheFormComponent />
)
}