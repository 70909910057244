import React, {useEffect, useState} from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

const Clients = () => {
  const isBrowser = () => typeof window !== "undefined"
    const data = useStaticQuery(graphql`
      fragment blurredload on File {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
      }
      query {
        phillip: file(relativePath: {eq: "testimonials/1.jpg"}) {
          ...blurredload
        },
        daniela: file(relativePath: {eq: "testimonials/2.jpg"}) {
          ...blurredload
        },
        mike: file(relativePath: {eq: "testimonials/3.jpg"}) {
          ...blurredload
        }
      }
    `)
    
    let clients = [
        {
            id: 0,
            name: 'Phillip Wang',
            position: 'Senior User Acquisition & Monetization Manager at Yodel Voice',
            imgPerson: data.phillip.childImageSharp.gatsbyImageData,
            quote: 'Very good follow up, quick iterations, strong knowledge….This was the first time we work and we were impressed with their work'
        },
        {
            id: 1,
            name: 'Daniela Huiskens',
            position: 'Video Producer at MediaCube',
            imgPerson: data.daniela.childImageSharp.gatsbyImageData,
            quote: 'MediaCube also challenges us on the product. They don’t just do whatever we say. They have given us quite good feedback on the product itself'
        },
        {
            id: 2,
            name: 'Mike Waldron',
            position: 'CEO at Avalon Group',
            imgPerson: data.mike.childImageSharp.gatsbyImageData,
            quote: 'Avalon Group executed a functional and reliable app that has exceeded user behavior and engagement KPIs. The proactive team excels at managing expectations and meeting milestones, delivering on time and budget.'
        }
    ]    

    
    const [activeID, setActiveID] = useState(0);

    var ids = [...Array(clients.length).keys()]
    const cslides = isBrowser() ? document.getElementsByClassName("clients__left-button"): null;
    const cdescr = isBrowser() ? document.getElementsByClassName("clients__right-container"): null;

    function onChange(toIndex) {
        let go = activeID + toIndex;
        let goIndex;
        if(go<0){
          goIndex=clients.length - 1;
        }
        else if(go>clients.length - 1)
        {
          goIndex = 0;
        }
        else{
          goIndex = go;
        }
        showSlide(goIndex);
    }
    
    function showSlide(toIndex) {
        let i;
    
        for (i = 0; i < clients.length; i++) {
            if(i===toIndex){
                cslides[i].className = 'clients__left-button clients__left-button--0';
                cdescr[i].className = 'clients__right-container clients__right-container--0';
                setActiveID(i);
            }
            else{
                cslides[i].className = 'clients__left-button';
                cdescr[i].className = 'clients__right-container';
            }
        }
    }
    
    const [isRunning, setIsRunning] = useState(true);

    useEffect(() => {
      const interval = setInterval(() => {
        if(isRunning){
          onChange(+1)
        }
      }, 2000);
      return () => clearInterval(interval);
    }, [isRunning]);

    const handlePauseToggle = () => {
      setIsRunning(!isRunning);
    }

    return (

        <div className="clients-out">
            <div className="clients__slider clients container" onMouseEnter={() => handlePauseToggle()} onMouseLeave={() => handlePauseToggle()}>
                <div className="clients__row row">
                    <div className="clients__left col-md-6 col-12">
                        {clients.map(client => (
                            <div className={"clients__left-out clients__left-out--" + client.id} key={"photo" + client.id}>
                                <button
                                    className={"clients__left-button clients__left-button--" + client.id}
                                    onClick={() => showSlide(client.id)} 
                                >
                                  <GatsbyImage
                                      image={client.imgPerson}
                                      className="clients__left--person"
                                      alt={client.name}
                                  />
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="clients__right col-md-6 col-12">
                      <div className="clients__right-inner">
                        <p className="clients__right-subtitle">
                          What our clients say
                        </p>
                        <h2 className="clients__right-title">Testimonials</h2>
                        {clients.map(client => (
                            <div
                                className={"clients__right-container clients__right-container--" + client.id}
                                key={client.id}
                            >
                            <p className="clients__right-descr">
                                {client.quote}
                            </p>
                            <div className="clients__right-who">
                                <div className="clients__right-who--inner">
                                    <h3 className="clients__right-who--name">{client.name}</h3>
                                    <p className="clients__right-who--position">{client.position}</p>
                                </div>
                            </div>
                            </div>
                        ))}
                        <div className="clients__buttonContainer">
                          <button onClick={() => onChange(-1)} className="clients__nav-button clients__nav-button--prev">
                              <img
                                          src="/images/left.svg"
                                          alt=""
                              />
                          </button>
                          <button onClick={() => onChange(+1)} className="clients__nav-button clients__nav-button--next">
                              <img
                                          src="/images/right.svg"
                                          alt=""
                              />
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clients