import * as React from "react";
import { Link } from "gatsby";

const Footer = () => (
  <div className="footer">
    <footer className="footer-container container">
      <div className="row">

        {/*<div className="col-md-12 col-12">
            <img
                alt=''
                className='footer__logo'
                src='/images/logo-f.svg'
            />
            <ul className='soc__icons'>
                <li className='soc__icons-title'>
                <a href='/'>
                    <img
                        alt='Twitter'
                        className='soc__icons--image'
                        src='/images/twitter.svg'
                    />
                </a>
                </li>
                <li className='soc__icons-title'>
                <a href='/'>
                    <img
                        alt='Facebook'
                        className='soc__icons--image'
                        src='/images/facebook.svg'
                    />
                </a>
                </li>
            </ul>
        </div>

        <div className="footerhr"/>*/}

        <div className="col-md-12 col-12 hor padn end">
              <ul>
                <li><Link to="/privacy">Privacy Policy</Link></li>
                <li className="hidemob">|</li>
                <li><Link to="/terms">Terms &amp; conditions</Link></li>
                <li className="hidemob">|</li>
                <li><p>© {new Date().getFullYear()} Matrix Media. All rights reserved.</p></li>
              </ul>
            </div>
      </div>
    </footer>
  </div>
);

export default Footer;
