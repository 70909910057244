import React, { useRef,useEffect, useState, useMemo } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/header";
import Footer from "../components/footer";
import Form from "../components/form";
import { Link } from "gatsby";
import SocPartners from "../components/socialPartners";
import Testimonials from "../components/testimonials";
import OriginVideo from '../video/back.mp4';

const useHover = () => {
  const [hovered, setHovered] = useState();
  
  const eventHandlers = useMemo(() => ({
    onMouseOver() { setHovered(true); },
    onMouseOut() { setHovered(false); }
  }), []);
  
  return [hovered, eventHandlers];
}

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const IndexPage = () => {
  const [hovered, eventHandlers] = useHover();
  
  const [isActive, setActive] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current.children[0];

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return (
    <Layout classmain="homepage">
      <Seo
        title='Welcome to Matrix Media'
        metaDesciption=''
      />
      <Header/>
      <div className='hp container'>
        <div className='hp__inner'>
          <div className='hp-video'>
            { shouldUseImage ? 
              <img src={OriginVideo} alt="MatrixMedia"/>
            : 
              <div
                ref={videoParentRef}
                dangerouslySetInnerHTML={{
                  __html: `
                  <video
                    loop
                    muted
                    autoplay
                    playsinline
                    preload="metadata"
                    id="mmVideo"
                    poster="/images/back.jpg"
                    class="hp__container-video"
                  >
                  <source src="${OriginVideo}" type="video/mp4" />
                  </video>`
                }}
              />
            }
          </div>
          <div className='hp-text'>
              <h1 className='hp--title'>
                Media Technology and Voice solutions
              </h1>
              <p className='hp--p'>Robust, fast engineering multiplied by Marketing</p>
              <a href="#contact" className='btn'>Contact us</a>
          </div>
        </div>
      </div>
      <div className='all container'>
        <div className={hovered? 'home hovered col-md-12 col-12' : 'home col-md-12 col-12'} onClick={()=> window.open("#services", "_self")}>
          <div {...eventHandlers} className="home__item home__item--v1">
            <h2 className='home__item--title'>Media</h2>
            <div className="home__inner--container">
              <div className="home__inner">
                <h2>Media</h2>
                <p>Matrix Media is an independent agency built on expertise and passion. We’re a real family of talent and dedicated to creating powerful advertising. Ultimately, our mission is to achieve the best campaign results and brand growth  for our clients .</p>
              </div>
            </div>
          </div>
          <div {...eventHandlers} className="home__item home__item--v2">
            <h2 className='home__item--title'>Technology</h2>
            <div className="home__inner--container">
              <div className="home__inner">
                <h2>Technology</h2>
                <p>Matrix Media is a product-oriented team. We create apps that achieve your business goals & hit the mark</p>
              </div>
            </div>
          </div>
          <div {...eventHandlers} className="home__item home__item--v3">
            <h2 className='home__item--title'>Voice</h2>
            <div className="home__inner--container">
              <div className="home__inner">
                <h2>Voice</h2>
                <p>Matrix Media's™ Human Powered A.I. Agents are designed to enhance interactions with Prospective clients or customers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='services-out' id="services">
        <div className='services container'>
          <h2 className='services__title'>Our Services</h2>
          <p className='services__subtitle'>Trust Matrix Media to design and enhance your campaigns</p>
          <div className='services__inner row'>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <StaticImage
                    alt='MatrixMedia'
                    className="services-img"
                    src='../images/tech.jpg'
                    width={1920}
                    quality='100'
                />
                <h3>Development</h3>
                <p>Product development in a box: at Matrix Media we got everything from Business Analysis, to Design and Development under 1 roof.</p>
              </div>
            </div>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <StaticImage
                    alt='MatrixMedia'
                    className="services-img"
                    src='../images/media.jpeg'
                    width={1920}
                    quality='100'
                />
                <h3>Marketing</h3>
                <p>Develop a Custom inbound or outbound campaign quickly. Ready to launch anytime</p>
              </div>
            </div>
            <div className='col-md-4 col-12 services__inner-col'>
              <div className='services__card'>
                <StaticImage
                    alt='MatrixMedia'
                    className="services-img"
                    src='../images/services.jpg'
                    width={1920}
                    quality='100'
                />
                <h3>Design</h3>
                <p>Our design is driven by user experience. The goal is to make complex look simple.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SocPartners/>
      <Testimonials/>
      <div className='contact' id="contact">
        <div className='contact__container container'>
            <div className='contact__col'>
              <p className='contact__subtitle'>Enough small talk</p>
              <h2 className='contact__title'>Let's get to work</h2>
                <a className='contact__phone' href="tel:7276088386">
                  <img src='/images/phone.svg'/>
                  (727) 608-8386</a>
                <a className='contact__mail' href="mailto:hello@matrixmedia.io">hello@matrixmedia.io</a>
                <p className='contact_address'>
                  989 Georgia Ave.<br/>
                  Palm Harbor, FL 34683
                </p>
            </div>
        </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default IndexPage
